import React, { useEffect, useState } from 'react'
import Loader from '../Components/Loader'
import AdminSidebar from '../Components/AdminSidebar'
import { isMobile } from 'react-device-detect'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { makeid } from '../Helper/helper'
import { toast } from 'react-toastify'
import firebaseApp from '../Firebase/firebase'
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa'
import Swal from 'sweetalert2'
import Countdown from 'react-countdown'

export default function AddSpecial() {
    const [showLoader, setShowLoader] = useState(true)
    const [AddSpecialModal, setAddSpecialModal] = useState(false);
    const [img, setImg] = useState("")
    const [editImg, setEditImg] = useState("")
    const [data, setData] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState('')
    const [view, setView] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const [currentLevel, setCurrentLevel] = useState([])
    const [isLevelEdit, setIsLevelEdit] = useState(false)
    const [levelEditId, setLevelEditId] = useState("")

    useEffect(() => {
        getData()
    }, [])

    const showView = () => {
        setView(true)
    }

    const closeView = () => {
        setView(false)
        levelFormik.resetForm()
    }

    const getData = () => {
        let x = []
        const db = firebaseApp.firestore()
        db.collection("Specials")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    setShowLoader(false)
                    x.push(doc.data())
                    setData(x)
                });
                if (querySnapshot.size == 0) {
                    setShowLoader(false)
                }
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    const handleView = (item) => {
        setSelectedData(item)
        setCurrentLevel(item.level)
        levelFormik.setFieldValue("number", item.level.length)
        showView()
    }

    const levelFormik = useFormik({
        initialValues: {
            number: 0,
            upCost: "",
            hourlyIncome: "",
            timeInMinute: 0,
        },
        validationSchema: Yup.object({
            number: Yup.number()
                .required("required!"),
            upCost: Yup.number()
                .required("required!"),
            hourlyIncome: Yup.number()
                .required("required!"),
        }),
        onSubmit: values => {
            if (isLevelEdit) {
                let obj = {
                    lvlNo: values.number,
                    upCost: values.upCost,
                    hourlyIncome: values.hourlyIncome,
                    timeInMinute: values.timeInMinute,
                    id: levelEditId ? levelEditId : makeid(10)
                }

                let index = 0
                if (levelEditId) {
                    index = currentLevel.findIndex((obj) => obj.id == levelEditId)
                } else {
                    index = currentLevel.findIndex((obj) => obj.lvlNo == values.number)
                }
                let oldData = currentLevel
                oldData[index] = obj
                console.log(obj, index)
                setCurrentLevel(oldData)
                setIsLevelEdit(false)
                setLevelEditId("")
                levelFormik.resetForm()
            } else {
                let obj = {
                    lvlNo: values.number,
                    upCost: values.upCost,
                    hourlyIncome: values.hourlyIncome,
                    id: makeid(10),
                    timeInMinute: values.timeInMinute
                }
                levelFormik.setFieldValue("number", Number(values.number) + 1)
                levelFormik.setFieldValue("upCost", "")
                levelFormik.setFieldValue("hourlyIncome", "")
                let arr = [...currentLevel, obj]
                setCurrentLevel(sortByKey(arr, 'lvlNo'))
            }
        }
    })

    function sortByKey(array, key) {
        return array.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            } else if (a[key] > b[key]) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    const getName = (id) => {
        const obj = data.find((obj) => obj.id == id)
        if (id == 'inviteFriend') {
            return "Friend"
        }
        return obj?.name

    }

    const handleLevelEdit = (item) => {
        levelFormik.setFieldValue('number', item.lvlNo)
        levelFormik.setFieldValue('upCost', item.upCost)
        levelFormik.setFieldValue('hourlyIncome', item.hourlyIncome)
        levelFormik.setFieldValue("timeInMinute", item.timeInMinute)
        setIsLevelEdit(true)
        setLevelEditId(item.id)
        console.log(item)
    }

    const closeAddSpecialModal = () => {
        formik.resetForm()
        setImg("")
        setEditImg("")
        setEditId("")
        setIsEdit(false)
        setAddSpecialModal(false)
    };

    const handleLevelDelete = (id) => {
        let oldData = currentLevel.filter((obj) => obj.id != id)
        setCurrentLevel(oldData)
    }

    const showAddSpecialModal = () => {
        setAddSpecialModal(true)
    };


    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            timeToStay: 0,
            color: "",
            requireToUnlock: false,
            requiredMiner: "",
            requiredLvl: "",
            isPermanent: true,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("!required"),
            description: Yup.string()
                .required("!required"),
            timeToStay: Yup.number()
                .required("!required"),
            color: Yup.string()
                .required("!required")
        }),
        onSubmit: value => {
            if (isEdit) {
                console.log("first")
                setShowLoader(true)
                UpdateDetails(value)
            } else {
                if (img == '') {
                    toast.error("!please select Image")
                } else {
                    setShowLoader(true)
                    value.id = makeid(10)
                    UploadImgToFirebase(value)
                }
            }
        }
    })

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            setShowLoader(true)
            if (result.isConfirmed) {
                const db = firebaseApp.firestore();
                db.collection('Specials').where("id", "==", id).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        db.collection("Specials").doc(doc.ref.id).delete().then(() => {
                            getData()
                            setShowLoader(false)
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            });

                        }).catch((error) => {
                            console.error("Error removing document: ", error);
                        });
                    })
                }).catch(err => {
                    console.error(err)
                })
            }
        });
    }

    const UpdateLevelToDatabase = () => {
        setShowLoader(true)
        const db = firebaseApp.firestore();
        db.collection('Specials').where("id", "==", selectedData.id).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Specials").doc(doc.ref.id);

                return updateCollection.update({
                    level: currentLevel
                })
                    .then(() => {
                        getData()
                        closeView()
                        setShowLoader(false)
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });

    }

    const UpdateDetails = (values) => {
        console.log("first", img)
        if (img == "") {
            console.log("first")
            values.img = editImg
            UpdateMiner(values)
        } else {
            UploadImgToFirebase(values)
        }
    }

    const UpdateMiner = (values) => {
        console.log("first", values)
        let obj = {
            name: values.name,
            img: values.img,
            hourlyIncome: 0,
            condition: {
                isRequire: values.requireToUnlock,
                requiredLvl: values.requiredLvl,
                requiredMiner: values.requiredMiner
            },
            discription: values.description,
            date: Date.now(),
            timeToStay: values.timeToStay,
            bgImg: values.color,
            isTimeCompleted: false,
            isPermanent: values.isPermanent
        }
        console.log(obj)
        const db = firebaseApp.firestore();
        db.collection('Specials').where("id", "==", editId).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Specials").doc(doc.ref.id);

                return updateCollection.update(obj)
                    .then(() => {
                        getData()
                        setShowLoader(false)
                        closeAddSpecialModal()
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const UploadImgToFirebase = (values) => {
        const file = img.target.files[0]
        const guid = () => {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return String(s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4());
        }


        let myPromise = new Promise((resolve, reject) => {

            const myGuid = guid();
            const storageUrl = firebaseApp.storage('gs://hamster-kombat-clone-dab00.appspot.com')
            const storageRef = storageUrl.ref();
            const uploadTask = storageRef.child('OwnerLogo').child('Images').child(myGuid).put(file)
            uploadTask.on('state_changed',
                (snapShot) => {

                }, (err) => {
                    //catches the errors
                    console.log(err)
                    reject(err)
                }, () => {

                    firebaseApp
                        .storage('gs://hamster-kombat-clone-dab00.appspot.com')
                        .ref()
                        .child('OwnerLogo')
                        .child('Images')
                        .child(myGuid)
                        .getDownloadURL()
                        .then(fireBaseUrl => {
                            resolve(fireBaseUrl)
                        }).catch(err => {
                            console.log('error caught', err)
                        })
                })
        })
        myPromise.then(url => {
            values.img = url
            if (isEdit) {
                UpdateMiner(values)
            } else {
                addMinerToDatabase(values)
            }
        }).catch(err => {
            console.log('error caught', err)
        })
    }

    const addMinerToDatabase = (values) => {
        let obj = {
            id: values.id,
            name: values.name,
            img: values.img,
            level: [],
            hourlyIncome: 0,
            condition: {
                isRequire: values.requireToUnlock,
                requiredLvl: values.requiredLvl,
                requiredMiner: values.requiredMiner
            },
            discription: values.description,
            date: Date.now(),
            timeToStay: values.timeToStay,
            bgImg: values.color,
            isTimeCompleted: false,
            isPermanent: values.isPermanent
        }
        const db = firebaseApp.firestore();
        db.collection("Specials").add(obj)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                getData()
                closeAddSpecialModal()
                setShowLoader(false)
                toast.success("Special Card Added Successfully")
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    };

    const convertToBase64 = (event) => {

        const imageFile = event.target.files[0];

        if (imageFile) {
            const reader = new FileReader();

            reader.readAsDataURL(imageFile);

            reader.addEventListener('load', () => {
                setEditImg(reader.result)
            });
        }
    };

    const handleFileChange = (e) => {
        setImg(e)
        convertToBase64(e)
    }

    const handleEdit = (item) => {
        showAddSpecialModal()
        setIsEdit(true)
        setEditId(item.id)
        setEditImg(item.img)
        formik.setFieldValue('name', item.name)
        formik.setFieldValue('description', item.discription)
        formik.setFieldValue('timeToStay', item.timeToStay)
        formik.setFieldValue('color', item.bgImg)
        formik.setFieldValue('requireToUnlock', item.condition.isRequire)
        formik.setFieldValue('requiredMiner', item.condition.requiredMiner)
        formik.setFieldValue('requiredLvl', item.condition.requiredLvl)
        formik.setFieldValue('isPermanent', item.isPermanent)
    }

    const isCompleted = (item) => {
        if (Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000) > Date.now()) {
            return false
        } else {
            return true
        }
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
        } else {
            // Render a countdown
            return <span>{(days * 24) + hours}:{minutes}:{seconds}</span>;
        }
    };

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-3">
                    <div className="d-flex justify-content-between">
                        <h3 className="text-center">Special Cards</h3>
                        <button className="btn btn-primary" onClick={showAddSpecialModal}>+ Add Special Cards</button>
                    </div>
                    <div className="row mt-4">
                        {data.map((item) => (
                            <div className="col-md-3">
                                <div className="mine-card shadow border rounded" style={{ background: item.bgImg }}>
                                    {!item.isPermanent ? <div className="countdown">
                                        {!isCompleted(item) ?
                                            <>
                                                <Countdown date={Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000)} renderer={renderer} />
                                            </>
                                            :
                                            <p className='text-center'>Expired</p>
                                        }
                                    </div> : ""}
                                    <div className='mine-img'>
                                        <img src={item.img} className='img-fluid' width={100} alt="" />
                                    </div>
                                    <div>
                                        <h4>Name : {item.name}</h4>
                                        <h6>Total Level : {item.level.length}</h6>
                                        <p>{item.condition.isRequire ? "required level " + item.condition.requiredLvl + " of " + getName(item.condition.requiredMiner) + " to unlock" : "no any Miner Level Needed to unlock"}</p>
                                    </div>
                                    <div className="d-flex">
                                        <button className="btn btn-secondary m-1" onClick={() => handleView(item)}><FaPlus /></button>
                                        <button className="btn btn-danger m-1" onClick={() => handleDelete(item.id)}><FaTrash /></button>
                                        <button className="btn btn-primary m-1" onClick={() => handleEdit(item)}><FaPen /></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal show={AddSpecialModal} onHide={closeAddSpecialModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Special Card</Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor='name'>Name : </label>
                                <input
                                    type="text"
                                    placeholder='Enter Card Name'
                                    className='form-control'
                                    id='name'
                                    name='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.name && formik.touched.name ? (
                                    <span className="text-danger">{formik.errors.name}</span>
                                ) : ""}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor='description'>Description : </label>
                                <input
                                    type="text"
                                    placeholder='Enter Description'
                                    className='form-control'
                                    id='description'
                                    name='description'
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.description && formik.touched.description ? (
                                    <span className="text-danger">{formik.errors.description}</span>
                                ) : ""}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Upload Image : </label>
                                <input type="file" className='form-control' onChange={handleFileChange} />
                                <div className="w-50">
                                    <img src={editImg} className='img-fluid' alt="" />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor='isPermanent'>Permanent : </label><br />
                                <Form.Check
                                    type='switch'
                                    id='isPermanent'
                                    name="isPermanent"
                                    checked={formik.values.isPermanent}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {formik.values.isPermanent ? "" : <div className="col-md-6 mb-3">
                                <label htmlFor='timeToStay'>Time To Stay (hours) : </label>
                                <input
                                    type="number"
                                    placeholder='Enter timeToStay'
                                    className='form-control'
                                    id='timeToStay'
                                    name='timeToStay'
                                    value={formik.values.timeToStay}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.timeToStay && formik.touched.timeToStay ? (
                                    <span className="text-danger">{formik.errors.timeToStay}</span>
                                ) : ""}
                            </div>}
                            <div className="col-md-6">
                                <label htmlFor='color'>Select Background Color : </label><br />
                                <input
                                    type="color"
                                    id='color'
                                    name='color'
                                    value={formik.values.color}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.color && formik.touched.color ? (
                                    <span className="text-danger">{formik.errors.color}</span>
                                ) : ""}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor='requireToUnlock'>Level Required To Unlock : </label><br />
                                <Form.Check
                                    type='switch'
                                    id='requireToUnlock'
                                    name="requireToUnlock"
                                    checked={formik.values.requireToUnlock}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor='requiredMiner'>Select Miner To Unlock : </label>
                                <select name="requiredMiner" id="requiredMiner" className='form-select' value={formik.values.requiredMiner} onChange={formik.handleChange}>
                                    <option value="">Select Miner</option>
                                    <option value="inviteFriend">Invite Friend</option>
                                    {data.map((item) => (
                                        <option value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor='requiredLvl'>Required Level : </label>
                                <input
                                    type="text"
                                    placeholder='Enter Required Miner Level / number of friend'
                                    className='form-control'
                                    id='requiredLvl'
                                    name='requiredLvl'
                                    value={formik.values.requiredLvl}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.requiredLvl && formik.values.requiredLvl ? (
                                    <span className="text-danger">{formik.errors.description}</span>
                                ) : ""}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type='button' onClick={closeAddSpecialModal}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal show={view} onHide={closeView} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Miner Details</Modal.Title>
                </Modal.Header>
                <form onSubmit={levelFormik.handleSubmit}>
                    <Modal.Body>
                        <div className="miner-detail">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="number">Level No :</label>
                                    <input
                                        type="text"
                                        name='number'
                                        id='number'
                                        value={levelFormik.values.number}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Level No'
                                    />
                                    {levelFormik.errors.number && levelFormik.touched.number ? (
                                        <div className="text-danger">{levelFormik.errors.number}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="upCost">Upgrade Cost :</label>
                                    <input
                                        type="text"
                                        name='upCost'
                                        id='upCost'
                                        value={levelFormik.values.upCost}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Upgrade cost'
                                    />
                                    {levelFormik.errors.upCost && levelFormik.touched.upCost ? (
                                        <div className="text-danger">{levelFormik.errors.upCost}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="hourlyIncome">Hourly Income :</label>
                                    <input
                                        type="text"
                                        name='hourlyIncome'
                                        id='hourlyIncome'
                                        value={levelFormik.values.hourlyIncome}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Hourly Income'
                                    />
                                    {levelFormik.errors.hourlyIncome && levelFormik.touched.hourlyIncome ? (
                                        <div className="text-danger">{levelFormik.errors.hourlyIncome}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="timeInMinute">Time in Second :</label>
                                    <input
                                        type="number"
                                        name='timeInMinute'
                                        id='timeInMinute'
                                        value={levelFormik.values.timeInMinute}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Upgrade Time'
                                    />
                                    {levelFormik.errors.timeInMinute && levelFormik.touched.timeInMinute ? (
                                        <div className="text-danger">{levelFormik.errors.timeInMinute}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-12 mb-3 mt-4">
                                    <button className="btn btn-primary w-100" type='submit'>Submit</button>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Table responsive="md">
                                    <thead>
                                        <tr>
                                            <td>Level No</td>
                                            <td>Upgrad Cost</td>
                                            <td>Hourly Income</td>
                                            <td>Time in minute</td>
                                            <td>Edit</td>
                                            <td>Delete</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentLevel.length > 0 && currentLevel.map((item) => (
                                            <tr>
                                                <td>{item.lvlNo}</td>
                                                <td>{item.upCost}</td>
                                                <td>{item.hourlyIncome}</td>
                                                <td>{item.timeInMinute}</td>
                                                <td>
                                                    <button className='btn btn-primary' type='button' onClick={() => handleLevelEdit(item)}>Edit</button>
                                                </td>
                                                <td>
                                                    <button className='btn btn-danger' type='button' onClick={() => handleLevelDelete(item.id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeView}>
                            Close
                        </Button>
                        <Button variant="primary" type='button' onClick={UpdateLevelToDatabase}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
