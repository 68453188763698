import React, { useEffect, useState } from 'react'
import Loader from '../Components/Loader'
import AdminSidebar from '../Components/AdminSidebar'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import firebaseApp from '../Firebase/firebase'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { FaEye } from 'react-icons/fa'
import { Button, Modal, Table } from 'react-bootstrap'
import axios from 'axios'

export default function Package() {
    const [showLoader, setShowLoader] = useState(false)
    const { id } = useParams()
    const [packageData, setPackageData] = useState([])
    const [completedPackage, setCompletedPackage] = useState([])
    const [earningData, setEarningData] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        getPackage()
    }, [])

    const getPackage = () => {
        let x = []
        let y = []
        axios.post(process.env.REACT_APP_API_URL + '/package/get-package', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    res.data.forEach((obj) => {
                        if (obj.isPackageCompleted) {
                            x.push(obj)
                        } else {
                            y.push(obj)
                        }
                        setCompletedPackage(x)
                        setPackageData(y)
                        setShowLoader(false)
                    })
                }
            })
    }

    const col = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{moment(value).format('DD-MM-YYYY')}</p>
                    )
                }
            }
        },
        {
            name: "investAmount",
            label: "Invested",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p >{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
        {
            name: "totalGet",
            label: "Received",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p >{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
        {
            name: "Earning",
            label: "View",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <button className="btn btn-info" onClick={() => showModal(value)}><FaEye /></button>
                    )
                }
            }
        },

    ]

    const showModal = (earning) => {
        setEarningData(earning)
        handleShow()
    }

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage: 100,
        pagination: false
    };


    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <MUIDataTable
                                title={"Running Package"}
                                data={packageData}
                                columns={col}
                                options={options}
                            />
                        </div>
                        <div className="col-md-6">
                            <MUIDataTable
                                title={"Completed package"}
                                data={completedPackage}
                                columns={col}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {earningData.map((item) => (
                                <tr>
                                    <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                    <td>{Number(item.amount).toFixed(2)}</td>
                                    <td>{item.earningType}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}
