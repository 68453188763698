import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Components/AdminSidebar'
import Loader from '../Components/Loader'
import { isMobile } from 'react-device-detect'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import { makeid } from '../Helper/helper'
import * as Yup from 'yup'
import firebaseApp from '../Firebase/firebase'
import { toast } from 'react-toastify'
import Select from 'react-select';
import moment from 'moment'
import axios from 'axios'


export default function DailyCombo() {

    const [showLoader, setShowLoader] = useState(false)
    const [dailycombodata, setDailyComboData] = useState([])
    const [minorsdata, setMinorsData] = useState([])
    // const [leveldata, setlevelData] = useState([])
    const [show, setShow] = useState(false);
    const [date, setDate] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [id, setId] = useState('')

    useEffect(() => {
        getMinorsData()
        getDailyCombos()
    }, [])

    const getMinorsData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/miner/get-miner-combo')
            .then((res) => {
                if (res.status == 200) {
                    setMinorsData(res.data)
                    setShowLoader(false)
                }
            })
    }

    const getDailyCombos = () => {
        axios.get(process.env.REACT_APP_API_URL + '/task/get-all-combo')
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                    setDailyComboData(res.data)
                }
            })
    }

    const handleClose = () => {
        setShow(false)
        // formik.resetForm()
    };
    const handleShow = () => setShow(true);

    // const handleSubmit = () => {
    //     const taskobj = {
    //         date: date,
    //         task: selectedOption
    //     }

    //     setDailyComboData([...dailycombodata, taskobj])
    // }

    const addDailyCombosToDatabase = () => {
        const taskobj = {
            date: new Date(date).getTime(),
            task: selectedOption,
            id: makeid(10)
        }
        axios.post(process.env.REACT_APP_API_URL + '/task/add-combo', taskobj)
            .then((res) => {
                if (res.status == 200) {
                    getDailyCombos()
                    handleClose()
                    toast.success("Task Added Successfully")
                }
            })

    }



    const EditMinors = (item) => {
        // console.log(item)
        // const EditItem = dailycombodata.find((obj) => obj.id == item.id)
        setId(item.id)
        handleShow()
        setDate(item.date)

        // console.log(singleItem)

        // setDate(EditItem)

    }

    // const YTTaskUpdate = () => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Daily-Combos').where("id", "==", id).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Daily-Combos").doc(doc.ref.id);

    //             return updateCollection.update({
    //                 date: new Date(date).getTime(),
    //                 task: selectedOption,
    //             })
    //                 .then(() => {
    //                     getDailyCombos()
    //                     handleClose()
    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    const DeleteDailyCombos = (id) => {
        axios.post(process.env.REACT_APP_API_URL + '/task/delete-combo', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    getDailyCombos()
                }
            })
    }



    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-3">
                    <div className="d-flex justify-content-between">
                        <h3 className="text-center">Daily Tasks</h3>
                        <button className="btn btn-primary" onClick={handleShow}>+ Add</button>
                    </div>

                    <div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>combo1</th>
                                    <th>combo2</th>
                                    <th>combo3</th>
                                    {/* <th>Edit</th> */}
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // console.log(moment(item.date.seconds * 1000).format('DD-MM-YYYY'))
                                    dailycombodata && dailycombodata.map((item) => {
                                        return (
                                            <tr >
                                                <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                                <td>
                                                    <img src={item.task[0].img} width={100} alt="" /><br />
                                                    {item.task[0].label}
                                                </td>
                                                <td>
                                                    <img src={item.task[1].img} width={100} alt="" /><br />
                                                    {item.task[1].label}
                                                </td>
                                                <td>
                                                    <img src={item.task[2].img} width={100} alt="" /><br />
                                                    {item.task[2].label}
                                                </td>
                                                {/*<td><button className='btn btn-primary' onClick={() => EditMinors(item)}>Edit</button></td>*/}
                                                <td><button className='btn btn-danger' onClick={() => DeleteDailyCombos(item._id)}>Delete</button></td>
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>






            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Daily Tasks</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Select Date</Form.Label>
                            <Form.Control type="date" onChange={(e) => setDate(e.target.value)} placeholder="Select Date" />
                        </Form.Group>
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={minorsdata}
                            isMulti
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addDailyCombosToDatabase}>
                        Submit
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}


