import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Components/AdminSidebar'
import Loader from '../Components/Loader'
import { isMobile } from 'react-device-detect'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { makeid } from '../Helper/helper';
import { toast } from 'react-toastify'
import firebaseApp from '../Firebase/firebase'
import axios from 'axios'



export default function EarningSection() {
    const [showLoader, setShowLoader] = useState(false)
    const [ytTask, setYTTask] = useState([])
    const [show, setShow] = useState(false);
    const [ytTaskId, setYTTaskId] = useState('')
    const [simpleTask, setSimpleTask] = useState([])
    const [simpletaskshow, setSimpleTaskShow] = useState(false);
    const [simpletaskimg, setSimpleTaskImg] = useState('')
    const [simpleTaskId, setSimpleTaskId] = useState('')




    useEffect(() => {
        getYTTaskData()
        getSimpleTaskData()
    }, [])


    const getYTTaskData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/yt/get-YTTask')
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                    setYTTask(res.data)
                }
            })
    }

    const getSimpleTaskData = () => {
        // let x = []
        // const db = firebaseApp.firestore()
        // db.collection("Simple Task")
        //     .get()
        //     .then((querySnapshot) => {
        //         querySnapshot.forEach((doc) => {
        //             // doc.data() is never undefined for query doc snapshots
        //             console.log(doc.id, " => ", doc.data());
        //             setShowLoader(false)
        //             x.push(doc.data())
        //             setSimpleTask(x)
        //         });
        //         console.log(querySnapshot)
        //         if (querySnapshot.size == 0) {
        //             setShowLoader(false)
        //         }
        //     })
        //     .catch((error) => {
        //         console.log("Error getting documents: ", error);
        //     });
    }


    const handleClose = () => {
        setShow(false)
        formik.resetForm()
    };
    const handleShow = () => setShow(true);

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            price: "",
            url: ""
        },
        validationSchema: Yup.object({
            // name: Yup.string()
            //     .required("required!"),
            // description: Yup.string()
            //     .required("required!"),
            // price: Yup.number()
            //     .required("required!"),
            // url: Yup.string()
            //     .required("required!")
        }),
        onSubmit: values => {
            if (ytTaskId) {
                YTTaskUpdate(values)
            } else {
                values.id = makeid(10)
                addYTTaskToDatabase(values)
            }
        }
    })

    const addYTTaskToDatabase = (values) => {

        axios.post(process.env.REACT_APP_API_URL + '/yt/add-YTTask', values)
            .then((res) => {
                if (res.status == 200) {
                    getYTTaskData()
                    handleClose()
                    toast.success("Task Added Successfully")
                }
            })

    }


    const EditYTTaskData = (item) => {
        setYTTaskId(item._id)
        handleShow()
        formik.setFieldValue('title', item.title)
        formik.setFieldValue('description', item.description)
        formik.setFieldValue('price', item.price)
        formik.setFieldValue('url', item.url)
    }


    const YTTaskUpdate = (values) => {
        axios.post(process.env.REACT_APP_API_URL + '/yt/update-YTTask', {
            id: ytTaskId, obj: {
                title: values.title,
                description: values.description,
                price: values.price,
                url: values.url
            }
        })
            .then((res) => {
                if (res.status == 200) {
                    getYTTaskData()
                    handleClose()
                }
            })
    }

    const DeleteYTTaskData = (id) => {
        axios.post(process.env.REACT_APP_API_URL + '/yt/delte-YTTask', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    getYTTaskData()
                }
            })
    }







    // -----------------------------------------------------------------------------------------------------------


    const CloseSimpleTask = () => {
        setSimpleTaskShow(false)
        formik.resetForm()
    };
    const ShowSimpleTask = () => setSimpleTaskShow(true);

    const simpleTaskformik = useFormik({
        initialValues: {
            title: "",
            price: "",
            buttonname: "",
            bigbutton: false,
        },
        validationSchema: Yup.object({
            // title: Yup.string()
            //     .required("required!"),
            // price: Yup.number()
            //     .required("required!"),
            // buttonname: Yup.string()
            //     .required("required!")
        }),
        onSubmit: values => {

            if (simpleTaskId) {
                UpdateSimpleTask(values)
            } else {
                values.id = makeid(10)
                UploadImgToFirebase(values)
            }
        }
    })

    const addSimpleTaskToDatabase = (values) => {
        console.log("first")
        let registerQuery = new Promise((resolve, reject) => {
            let db = firebaseApp.firestore();
            db.collection("Simple Task").add(values)
                .then((docRef) => {
                    console.log("Document written with ID: ", docRef.id);
                    getSimpleTaskData()
                    CloseSimpleTask()
                    toast.success("Task Added Successfully")
                })
                .catch((error) => {
                    console.error("Error adding Task: ", error);
                });
        });
        registerQuery.then(result => {
            console.warn('register successful')
        }).catch(error => {
            console.error(error)
        })

    }

    const handleFileChange = (e) => {
        setSimpleTaskImg(e)
    }

    const UploadImgToFirebase = (values) => {
        const file = simpletaskimg.target.files[0]
        const guid = () => {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return String(s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4());
        }


        let myPromise = new Promise((resolve, reject) => {

            const myGuid = guid();
            const storageUrl = firebaseApp.storage('gs://hamster-kombat-clone-dab00.appspot.com')
            const storageRef = storageUrl.ref();
            const uploadTask = storageRef.child('OwnerLogo').child('Images').child(myGuid).put(file)
            uploadTask.on('state_changed',
                (snapShot) => {

                }, (err) => {
                    //catches the errors
                    console.log(err)
                    reject(err)
                }, () => {

                    firebaseApp
                        .storage('gs://hamster-kombat-clone-dab00.appspot.com')
                        .ref()
                        .child('OwnerLogo')
                        .child('Images')
                        .child(myGuid)
                        .getDownloadURL()
                        .then(fireBaseUrl => {
                            resolve(fireBaseUrl)
                        }).catch(err => {
                            console.log('error caught', err)
                        })
                })
        })
        myPromise.then(url => {
            values.img = url
            addSimpleTaskToDatabase(values)
        }).catch(err => {
            console.log('error caught', err)
        })
    }


    const EditSimpleTask = (item) => {
        setSimpleTaskId(item.id)
        ShowSimpleTask()
        simpleTaskformik.setFieldValue('image', item.img)
        simpleTaskformik.setFieldValue('title', item.title)
        simpleTaskformik.setFieldValue('price', item.price)
        simpleTaskformik.setFieldValue('buttonname', item.buttonname)
        simpleTaskformik.setFieldValue('bigbutton', item.bigbutton)
    }


    const UpdateSimpleTask = (values) => {
        const db = firebaseApp.firestore();
        db.collection('Simple Task').where("id", "==", simpleTaskId).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Simple Task").doc(doc.ref.id);

                return updateCollection.update({
                    image: values.img,
                    title: values.title,
                    price: values.price,
                    buttonname: values.buttonname
                })
                    .then(() => {
                        getSimpleTaskData()
                        CloseSimpleTask()
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const DeleteSimpleTask = (id) => {

        // console.log(id)
        console.log(id)
        const db = firebaseApp.firestore();

        db.collection("Simple Task").where("id", "==", id)

            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    db.collection("Simple Task").doc(doc.id).delete().then(() => {
                        console.log("Document successfully deleted!");
                        getSimpleTaskData()
                    }).catch((error) => {
                        console.error("Error removing document: ", error);
                    });
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

    }


    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-3">
                    <div className="d-flex justify-content-between">
                        <h3 className="text-center">Add YouTube Task</h3>
                        <button className="btn btn-primary" onClick={handleShow}>+ Add</button>
                    </div>

                    <div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Price</th>
                                    <th>URL</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ytTask.map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.title}</td>
                                                <td>{item.description}</td>
                                                <td>{item.price}</td>
                                                <td>{item.url}</td>
                                                <td><button className='btn btn-primary' onClick={() => EditYTTaskData(item)}>Edit</button></td>
                                                <td><button className='btn btn-danger' onClick={() => DeleteYTTaskData(item._id)}>Delete</button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* <div className="container mt-3">
                    <div className="d-flex justify-content-between">
                        <h3 className="text-center">Add Simpal Task</h3>
                        <button className="btn btn-primary" onClick={ShowSimpleTask}>+ Add</button>
                    </div>

                    <div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Button Name</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    simpleTask.map((item) => {
                                        return (
                                            <tr>
                                                <td><img src={item.img} width={50} alt="" /></td>
                                                <td>{item.title}</td>
                                                <td>{item.price}</td>
                                                <td>{item.buttonname}</td>
                                                <td><button className='btn btn-primary' onClick={() => EditSimpleTask(item)}>Edit</button></td>
                                                <td><button className='btn btn-danger' onClick={() => DeleteSimpleTask(item.id)}>Delete</button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div> */}
            </div>

            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Miner</Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="title">Title :</label>
                                <input
                                    type="text"
                                    className={formik.errors.title && formik.touched.title ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Title'
                                    id='title'
                                    name='title'
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.title && formik.touched.title ? (
                                    <span className="text-danger">{formik.errors.title}</span>
                                ) : null}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="description">Description :</label>
                                <input
                                    type="text"
                                    className={formik.errors.description && formik.touched.description ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter description'
                                    id='description'
                                    name='description'
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.description && formik.touched.description ? (
                                    <span className="text-danger">{formik.errors.description}</span>
                                ) : null}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="price">Price :</label>
                                <input
                                    type="number"
                                    className={formik.errors.price && formik.touched.price ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Price'
                                    id='price'
                                    name='price'
                                    value={formik.values.price}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.price && formik.touched.price ? (
                                    <span className="text-danger">{formik.errors.price}</span>
                                ) : null}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="url">URL :</label>
                                <input
                                    type="text"
                                    className={formik.errors.url && formik.touched.url ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Url'
                                    id='url'
                                    name='url'
                                    value={formik.values.url}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.url && formik.touched.url ? (
                                    <span className="text-danger">{formik.errors.url}</span>
                                ) : null}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>


            <Modal show={simpletaskshow} onHide={CloseSimpleTask} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Miner</Modal.Title>
                </Modal.Header>
                <form onSubmit={simpleTaskformik.handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="img">Upload Image</label>
                                <input type="file" className='form-control' id='img' onChange={handleFileChange} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="title">Title :</label>
                                <input
                                    type="text"
                                    className={simpleTaskformik.errors.title && simpleTaskformik.touched.title ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Title'
                                    id='title'
                                    name='title'
                                    value={simpleTaskformik.values.title}
                                    onChange={simpleTaskformik.handleChange}
                                />
                                {simpleTaskformik.errors.description && simpleTaskformik.touched.description ? (
                                    <span className="text-danger">{simpleTaskformik.errors.description}</span>
                                ) : null}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="price">Price :</label>
                                <input
                                    type="number"
                                    className={simpleTaskformik.errors.price && simpleTaskformik.touched.price ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Price'
                                    id='price'
                                    name='price'
                                    value={simpleTaskformik.values.price}
                                    onChange={simpleTaskformik.handleChange}
                                />
                                {simpleTaskformik.errors.price && simpleTaskformik.touched.price ? (
                                    <span className="text-danger">{simpleTaskformik.errors.price}</span>
                                ) : null}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="buttonname">Button Name :</label>
                                <input
                                    type="text"
                                    className={simpleTaskformik.errors.buttonname && simpleTaskformik.touched.buttonname ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Button Name'
                                    id='buttonname'
                                    name='buttonname'
                                    value={simpleTaskformik.values.buttonname}
                                    onChange={simpleTaskformik.handleChange}
                                />
                                {simpleTaskformik.errors.buttonname && simpleTaskformik.touched.buttonname ? (
                                    <span className="text-danger">{simpleTaskformik.errors.buttonname}</span>
                                ) : null}
                            </div>

                            <div className='d-flex'>
                                <label htmlFor="bigbutton">Big btn:</label>
                                <Form.Check // prettier-ignore
                                    className='ms-3'
                                    type="switch"
                                    id="bigbutton"
                                    name='bigbutton'
                                    // label="Big Btn"
                                    checked={simpleTaskformik.values.bigbutton}
                                    onChange={simpleTaskformik.handleChange}
                                />
                                {simpleTaskformik.errors.bigbutton && simpleTaskformik.touched.bigbutton ? (
                                    <span className="text-danger">{simpleTaskformik.errors.bigbutton}</span>
                                ) : null}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
