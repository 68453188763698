import React, { useContext, useEffect, useState } from 'react'
import Loader from '../Components/Loader'
import AdminSidebar from '../Components/AdminSidebar'
import { isMobile } from 'react-device-detect'
import firebaseApp from '../Firebase/firebase'
import { context } from '../Context/UserContext'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { MdHistory } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { FaEye, FaTrash } from 'react-icons/fa'
import Swal from 'sweetalert2'
import axios from 'axios'

export default function AdminAnalytics() {
    const [showLoader, setShowLoader] = useState(true)
    const [userData, setUserData] = useState([])

    const { allUser, setAllUser, activeUser, setActiveUser, inActiveUser, setInActiveUser, todayAUser, todayIUser, setTodayAUser, setTodayIUser } = useContext(context)
    const [filter, setFilter] = useState(2)
    const [tableData, setTableData] = useState([])


    useEffect(() => {
        if (allUser.length == 0) {
            getUserData()
        } else {
            setUserData(allUser)
            setShowLoader(false)
        }
    }, [])

    useEffect(() => {
        setTableData(userData)
    }, [userData])


    useEffect(() => {
        if (filter == 0) {
            let active = userData.filter((obj) => obj.myDeposit > 0)
            setTableData(active)
        }
        if (filter == 1) {
            let inactive = userData.filter((obj) => obj.myDeposit == 0)
            setTableData(inactive)
        }
        if (filter == 2) {
            setTableData(userData)
        }
        let todayUser = userData.filter((obj) => moment(obj.date).format('DD-MM-YYYY') == moment(Date.now()).format('DD-MM-YYYY'))
        if (filter == 3) {
            let active = todayUser.filter((obj) => obj.myDeposit > 0)
            setTableData(active)
        }
        if (filter == 4) {
            let inactive = todayUser.filter((obj) => obj.myDeposit == 0)
            setTableData(inactive)
        }
    }, [filter])



    const getUserData = () => {
        let inactive = 0
        let active = 0
        let Tinactive = 0
        let Tactive = 0
        axios.get(process.env.REACT_APP_API_URL + '/user/get-all-user')
            .then((res) => {
                if (res.status == 200 && res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].myDeposit == 0) {
                            inactive++;
                        } else {
                            active++;
                        }

                        // Check if the deposit was made today
                        if (moment(res.data[i].date).isSame(moment(), 'day')) {
                            if (res.data[i].myDeposit == 0) {
                                Tinactive++;
                            } else {
                                Tactive++;
                            }
                        }
                    }
                    setUserData(sortByKey(res.data, 'date'));
                    setTableData(res.data);
                    setAllUser(res.data);
                    setShowLoader(false);
                    setInActiveUser(inactive)
                    setActiveUser(active)
                    setTodayAUser(Tactive)
                    setTodayIUser(Tinactive)
                }
            })
    }

    function sortByKey(array, key) {
        return array.sort((a, b) => {
            if (a[key] > b[key]) {
                return -1;
            } else if (a[key] < b[key]) {
                return 1;
            } else {
                return 0;
            }
        });
    }



    const columns = [
        {
            name: "id",
            label: "USER ID",
            options: {
                filter: false,
                sort: false,
                display: true
            }
        },
        // {
        //     name: "tgId",
        //     label: "Telegram ID",
        //     options: {
        //         filter: false,
        //         sort: false,
        //     }
        // },
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value) {
                        return (
                            <p>{moment(value).format('DD-MM-YYYY HH:mm')}</p>
                        )
                    } else {
                        return (
                            <p>14-08-2024</p>
                        )
                    }

                }
            }
        },
        {
            name: "telegramId",
            label: "Telegram Name",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value == null) {
                        return (
                            <p>null</p>
                        )
                    } else if (typeof value == 'string') {
                        return (
                            <p>Pratham PC</p>
                        )
                    } else {
                        return (
                            <p>{value.first_name} {value.last_name}</p>
                        )
                    }

                }
            }
        },
        {
            name: "tonId",
            label: "walletAddress",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "myDeposit",
            label: "Deposit",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
        {
            name: "myBalance",
            label: "Withdrawable Balance",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <p>{value}</p>
                    )
                }
            }
        },
        {
            name: "mypoints",
            label: "Points",
            options: {
                filter: false,
                sort: false,

            }
        },
        {
            name: "profitPerHour",
            label: "Profit Per hour",
            options: {
                filter: false,
                sort: false,

            }
        },
        {
            name: "myFriends",
            label: "Total Friends",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{value.length}</p>
                    )
                }
            }
        },
        {
            name: "refId",
            label: "Refered By",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{returnReferalName(value)}</p>
                    )
                }
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <label class="switch">
                            <input type="checkbox" checked={value == 1 ? true : false} onChange={(e) => ChangeStatus(e, tableMeta, value)} />
                            <span class="slider round"></span>
                        </label>
                    )
                }
            }
        },
        {
            name: "id",
            label: "History",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Link className="btn btn-primary" to={'/history/' + value} >
                            <MdHistory style={{ fontSize: 20 }} />
                        </Link>
                    )
                }
            }
        },
        {
            name: "id",
            label: "Package",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Link className="btn btn-info" to={'/package/' + value} >
                            <FaEye style={{ fontSize: 20 }} />
                        </Link>
                    )
                }
            }
        },
        {
            name: "id",
            label: "Delete",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <button className='btn btn-danger' onClick={() => handleDelete(value)}><FaTrash /></button>
                    )
                }
            }
        },
    ];

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteFromDataBase(id)
            }
        });
    }

    const DeleteFromDataBase = (id) => {
        setShowLoader(true)
        axios.post(process.env.REACT_APP_API_URL + '/user/delete-user', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                }
            })
    }

    function findDuplicates(array) {
        const tgIdCount = {};
        const duplicates = new Set();

        // Count occurrences of each tgId
        array.forEach(obj => {
            if (obj.tgId !== undefined) {
                if (tgIdCount[obj.tgId]) {
                    tgIdCount[obj.tgId]++;
                } else {
                    tgIdCount[obj.tgId] = 1;
                }
            }
        });

        // Find duplicates
        for (const [tgId, count] of Object.entries(tgIdCount)) {
            if (count > 1) {
                duplicates.add(tgId);
            }
        }

        return Array.from(duplicates);
    }

    // const removeRefFromUsers = (id) => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("refId", "==", id).get().then((querySnapshot) => {
    //         if (querySnapshot.empty) {
    //             getUserData()
    //             setShowLoader(false)
    //             Swal.fire({
    //                 title: "Deleted!",
    //                 text: "Your file has been deleted.",
    //                 icon: "success"
    //             });
    //         } else {
    //             querySnapshot.forEach((doc) => {
    //                 var updateCollection = db.collection("Users").doc(doc.ref.id);

    //                 return updateCollection.update({
    //                     refId: ""
    //                 })
    //                     .then(() => {
    //                         getUserData()
    //                         setShowLoader(false)
    //                         Swal.fire({
    //                             title: "Deleted!",
    //                             text: "Your file has been deleted.",
    //                             icon: "success"
    //                         });
    //                     })
    //                     .catch((error) => {
    //                         // The document probably doesn't exist.
    //                         console.error("Error updating document: ", error);
    //                     });
    //             })
    //         }
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    const returnReferalName = (id) => {
        let user = userData.find((obj) => obj.id == id)
        let userName = user?.telegramId?.first_name + " " + user?.telegramId?.last_name
        if (id == "") {
            return "No any Ref"
        } else {
            return userName
        }
    }

    const ChangeStatus = (e, tableMeta, value) => {
        // Get the user ID
        setShowLoader(true)
        const id = tableMeta.rowData[0];

        // Find the index of the user in the allUser array
        const index = allUser.findIndex((obj) => obj.id === id);

        // Create a new array with updated user status
        const updatedUsers = [...allUser];
        const user = updatedUsers[index];

        // Update user status
        user.status = value === 1 ? 0 : 1;

        // Update the state with the new array
        axios.post(process.env.REACT_APP_API_URL + '/user/change-user-status', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    setAllUser(updatedUsers);
                    setTableData(updatedUsers);
                    setUserData(updatedUsers);
                    setFilter(2);
                    setShowLoader(false)
                }
            })


    };
    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage: 100,
        pagination: false
    };


    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="mt-5 container-fluid">
                    <div className="container mt-5">
                        <div className="row justify-content-between mb-3">
                            <div className='col-sm-6 col-md-6 col-lg-2'>
                                <div className="shadow-sm bg-white border-radius-banner">
                                    <div className={filter == 0 ? "containerss banner-hoverd" : "containerss"} onClick={() => setFilter(0)}>
                                        <div className="contentss">
                                            <h4 className="asdff">Active User : </h4>
                                            <h4 className="asdff">{activeUser}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg-2'>
                                <div className="shadow-sm bg-white border-radius-banner">
                                    <div className={filter == 1 ? "containerss banner-hoverd" : "containerss"} onClick={() => setFilter(1)}>
                                        <div className="contentss">
                                            <h4 className="asdff">Inactive User : </h4>
                                            <h4 className="asdff">{inActiveUser}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg-2'>
                                <div className="shadow-sm bg-white border-radius-banner">
                                    <div className={filter == 3 ? "containerss banner-hoverd" : "containerss"} onClick={() => setFilter(3)}>
                                        <div className="contentss">
                                            <h4 className="asdff">Today Active User : </h4>
                                            <h4 className="asdff">{todayAUser}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg-2'>
                                <div className="shadow-sm bg-white border-radius-banner">
                                    <div className={filter == 4 ? "containerss banner-hoverd" : "containerss"} onClick={() => setFilter(4)}>
                                        <div className="contentss">
                                            <h4 className="asdff">Today Inactive User : </h4>
                                            <h4 className="asdff">{todayIUser}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg-2'>
                                <div className="shadow-sm bg-white border-radius-banner">
                                    <div className={filter == 2 ? "containerss banner-hoverd" : "containerss"} onClick={() => setFilter(2)}>
                                        <div className="contentss">
                                            <h4 className="asdff">Total User : </h4>
                                            <h4 className="asdff">{Number(activeUser) + Number(inActiveUser)}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MUIDataTable
                        title={filter == 0 ? "Active User" : filter == 1 ? "Inactive User" : filter == 3 ? "Today Active User" : filter == 4 ? "Today Inactive User" : "Total User"}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        </>
    )
}
