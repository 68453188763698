import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import EarningSection from './Pages/EarningSection';
import DailyCombo from './Pages/DailyCombo';
import DailyCipher from './Pages/DailyCipher';
import Login from './Pages/Login';
import ProtectedRouter from './Pages/ProtectedRouter';
import AddSpecial from './Pages/AddSpecial';
import AdminAnalytics from './Pages/AdminAnalytics';
import UserContext from './Context/UserContext';
import History from './Pages/History';
import Package from './Pages/Package';
import AdminTransaction from './Pages/AdminTransaction';
import AddDeposit from './Pages/AddDeposit';

export default function App() {
  return (
    <>
      <UserContext>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/home' element={<ProtectedRouter><Home /></ProtectedRouter>} />
            <Route path='/earningsection' element={<ProtectedRouter><EarningSection /></ProtectedRouter>} />
            <Route path='/dailycombo' element={<ProtectedRouter><DailyCombo /></ProtectedRouter>} />
            <Route path='/dailycipher' element={<ProtectedRouter><DailyCipher /></ProtectedRouter>} />
            <Route path='/special' element={<ProtectedRouter><AddSpecial /></ProtectedRouter>} />
            <Route path='/admin-analytics' element={<ProtectedRouter><AdminAnalytics /></ProtectedRouter>} />
            <Route path='/admin-transaction' element={<ProtectedRouter><AdminTransaction /></ProtectedRouter>} />
            <Route path='/history/:id' element={<ProtectedRouter><History /></ProtectedRouter>} />
            <Route path='/package/:id' element={<ProtectedRouter><Package /></ProtectedRouter>} />
            <Route path='/add-deposit' element={<ProtectedRouter><AddDeposit /></ProtectedRouter>} />
          </Routes>
        </BrowserRouter>
      </UserContext>
      <ToastContainer />
    </>
  )
}
