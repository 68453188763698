import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import AdminSidebar from '../Components/AdminSidebar'
import Loader from '../Components/Loader'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import moment from 'moment'
import { toast } from 'react-toastify'
import { makeid } from '../Helper/helper'
import firebaseApp from '../Firebase/firebase'
import axios from 'axios'

export default function DailyCipher() {

    const [showLoader, setShowLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [dailycipherdata, setDailyCipherdata] = useState([])
    const [date, setDate] = useState('')
    const [word, setWord] = useState('')


    useEffect(() => {
        getDailyCiphersdata()
    }, [])



    const handleClose = () => {
        setShow(false)
        // formik.resetForm()
    };
    const handleShow = () => setShow(true);

    const getDailyCiphersdata = () => {
        axios.get(process.env.REACT_APP_API_URL + '/task/get-all-cipher')
            .then((res) => {
                if (res.status == 200) {
                    setDailyCipherdata(res.data)
                    setShowLoader(false)
                }
            })
    }

    const addDailyCiphersToDatabase = () => {
        const taskobj = {
            date: new Date(date).getTime(),
            word: word,
            id: makeid(10)
        }
        axios.post(process.env.REACT_APP_API_URL + '/task/add-cipher', taskobj)
            .then((res) => {
                if (res.status == 200) {
                    getDailyCiphersdata()
                    handleClose()
                    toast.success("Task Added Successfully")
                }
            })
    }

    const DeleteDailyCiphers = (id) => {
        axios.post(process.env.REACT_APP_API_URL + '/task/delete-cipher', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    getDailyCiphersdata()
                    setShowLoader(false)
                    toast.error('Cipher Delete Seccessfully')
                }
            })
    }

    const click = () => {
        const db = firebaseApp.firestore();
        db.collection('Users').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Users").doc(doc.ref.id);

                return updateCollection.update({
                    dailyCipher: false,
                    achivedCombo: [],
                    comboAchive: false,
                    dailyTask: false
                })
                    .then(() => {

                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
            toast.success('DONE')
        }).catch(err => {
            console.error(err)
        });
    }

    // const click = () => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Users").doc(doc.ref.id);

    //             return updateCollection.update({
    //                 // tgId: doc.data().telegramId == 'not defined' ? doc.data().id : doc.data().telegramId.id
    //                 dailyTask: false
    //             })
    //                 .then(() => {

    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-3">
                    <div className="d-flex justify-content-between">
                        <h3 className="text-center">Daily Cipher</h3>
                        <button className="btn btn-primary" onClick={handleShow}>+ Add</button>
                    </div>
                    <div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>word</th>
                                    {/* <th>Edit</th> */}
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // console.log(moment(item.date.seconds * 1000).format('DD-MM-YYYY'))
                                    dailycipherdata && dailycipherdata.map((item) => {
                                        return (
                                            <tr >
                                                <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                                <td>{item.word}</td>

                                                {/* <td><button className='btn btn-primary' onClick={() => EditMinors(item)}>Edit</button></td> */}
                                                <td><button className='btn btn-danger' onClick={() => DeleteDailyCiphers(item._id)}>Delete</button></td>
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                        </Table>
                    </div>
                    {/* <button onClick={click}>RESET BUTTON</button> */}

                </div>
            </div>

            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Daily Ciphers</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Select Date</Form.Label>
                            <Form.Control type="date" onChange={(e) => setDate(e.target.value)} placeholder="Select Date" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="name" onChange={(e) => setWord(e.target.value)} placeholder="Enter Your Name" />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addDailyCiphersToDatabase}>
                        Submit
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}
