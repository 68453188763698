import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Components/AdminSidebar'
import { isMobile } from 'react-device-detect'
import { Button, Form, Modal, Tab, Table, Tabs } from 'react-bootstrap'
import { FormikConsumer, useFormik } from 'formik';
import * as Yup from 'yup'
import { makeid } from '../Helper/helper';
import firebaseApp from '../Firebase/firebase';
import Loader from '../Components/Loader';
import { toast } from 'react-toastify';
import { FaEye, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';



export default function Home() {
    const [show, setShow] = useState(false);
    const [img, setImg] = useState('')
    const [showLoader, setShowLoader] = useState(true)
    const [data, setData] = useState([])
    const [editId, setEditId] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [editImg, setEditImg] = useState('')
    const [view, setView] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const [currentLevel, setCurrentLevel] = useState([])
    const [isLevelEdit, setIsLevelEdit] = useState(false)
    const [levelEditId, setLevelEditId] = useState("")

    const showView = () => {
        setView(true)
    }

    const closeView = () => {
        setView(false)
        levelFormik.resetForm()
    }

    useEffect(() => {
        getData()
    }, [])


    const getData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/miner/get-miner')
            .then((res) => {
                if (res.status == 200) {
                    setData(res.data)
                    setShowLoader(false)
                }
            })
    }

    const handleView = (item) => {
        setSelectedData(item)
        setCurrentLevel(item.level)
        levelFormik.setFieldValue("number", item.level.length)
        showView()
    }

    const convertToBase64 = (event) => {

        const imageFile = event.target.files[0];

        if (imageFile) {
            const reader = new FileReader();

            reader.readAsDataURL(imageFile);

            reader.addEventListener('load', () => {
                setEditImg(reader.result)
            });
        }
    };


    const handleClose = () => {
        setShow(false)
        formik.resetForm()
        setImg("")
        setIsEdit(false)
        setEditImg("")
    };
    const handleShow = () => setShow(true);

    const levelFormik = useFormik({
        initialValues: {
            number: 0,
            upCost: "",
            hourlyIncome: "",
            timeInMinute: 0,
        },
        validationSchema: Yup.object({
            number: Yup.number()
                .required("required!"),
            upCost: Yup.number()
                .required("required!"),
            hourlyIncome: Yup.number()
                .required("required!"),
        }),
        onSubmit: values => {
            if (isLevelEdit) {
                let obj = {
                    lvlNo: values.number,
                    upCost: values.upCost,
                    hourlyIncome: values.hourlyIncome,
                    timeInMinute: values.timeInMinute,
                    id: levelEditId ? levelEditId : makeid(10)
                }

                let index = 0
                if (levelEditId) {
                    index = currentLevel.findIndex((obj) => obj.id == levelEditId)
                } else {
                    index = currentLevel.findIndex((obj) => obj.lvlNo == values.number)
                }
                let oldData = currentLevel
                oldData[index] = obj
                console.log(obj, index)
                setCurrentLevel(oldData)
                setIsLevelEdit(false)
                setLevelEditId("")
                levelFormik.resetForm()
            } else {
                let obj = {
                    lvlNo: values.number,
                    upCost: values.upCost,
                    hourlyIncome: values.hourlyIncome,
                    id: makeid(10),
                    timeInMinute: values.timeInMinute
                }
                levelFormik.setFieldValue("number", Number(values.number) + 1)
                levelFormik.setFieldValue("upCost", "")
                levelFormik.setFieldValue("hourlyIncome", "")
                let arr = [...currentLevel, obj]
                setCurrentLevel(sortByKey(arr, 'lvlNo'))
            }
        }
    })

    function sortByKey(array, key) {
        return array.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            } else if (a[key] > b[key]) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            maxLevel: "",
            category: "",
            requireLvl: false,
            requireMiner: "",
            requiredMinerLvl: "",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("required!"),
            maxLevel: Yup.string()
                .required("required!"),
            category: Yup.number()
                .required("required!")
        }),
        onSubmit: values => {
            console.log(values)
            setShowLoader(true)
            if (isEdit) {
                UpdateDetails(values)
            } else {
                if (img == '') {
                    toast.error("Please Select Image")
                    setShowLoader(false)
                } else {
                    values.id = makeid(10)
                    UploadImgToFirebase(values)
                }
            }
        }
    })

    const UpdateDetails = (values) => {
        console.log("first")
        if (img == "") {
            console.log("first")
            values.img = editImg
            UpdateMiner(values)
        } else {
            UploadImgToFirebase(values)
        }
    }

    const UpdateMiner = (values) => {
        let obj = {
            name: values.name,
            img: values.img,
            category: values.category,
            condition: {
                isRequire: values.requireLvl,
                requiredLvl: values.requiredMinerLvl,
                requiredMiner: values.requireMiner
            },
            discription: values.maxLevel
        }
        axios.post(process.env.REACT_APP_API_URL + '/miner/update-miner', { id: editId, obj })
            .then((res) => {
                if (res.status == 200) {
                    getData()
                    setShowLoader(false)
                    handleClose()
                }
            })
    }

    const handleFileChange = (e) => {
        setImg(e)
        convertToBase64(e)
    }


    const UploadImgToFirebase = (values) => {
        const file = img.target.files[0]
        const guid = () => {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return String(s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4());
        }


        let myPromise = new Promise((resolve, reject) => {

            const myGuid = guid();
            const storageUrl = firebaseApp.storage('gs://bankmanagement-3cecd.appspot.com')
            const storageRef = storageUrl.ref();
            const uploadTask = storageRef.child('Miner').child(myGuid).put(file)
            uploadTask.on('state_changed',
                (snapShot) => {

                }, (err) => {
                    //catches the errors
                    console.log(err)
                    reject(err)
                }, () => {

                    firebaseApp
                        .storage('gs://bankmanagement-3cecd.appspot.com')
                        .ref()
                        .child('Miner')
                        .child(myGuid)
                        .getDownloadURL()
                        .then(fireBaseUrl => {
                            resolve(fireBaseUrl)
                        }).catch(err => {
                            console.log('error caught', err)
                        })
                })
        })
        myPromise.then(url => {
            values.img = url
            if (isEdit) {
                UpdateMiner(values)
            } else {
                addMinerToDatabase(values)
            }
        }).catch(err => {
            console.log('error caught', err)
        })
    }

    const addMinerToDatabase = (values) => {
        let obj = {
            id: values.id,
            name: values.name,
            img: values.img,
            level: [],
            hourlyIncome: 0,
            category: values.category,
            condition: {
                isRequire: values.requireLvl,
                requiredLvl: values.requiredMinerLvl,
                requiredMiner: values.requireMiner
            },
            discription: values.maxLevel,
            date: Date.now()
        }
        axios.post(process.env.REACT_APP_API_URL + "/miner/add-miner", obj)
            .then((res) => {
                if (res.status == 200) {
                    getData()
                    handleClose()
                    setShowLoader(false)
                    toast.success("Miner Added Successfully")
                }
            })
    };



    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                setShowLoader(true)
                axios.post(process.env.REACT_APP_API_URL + '/miner/delete-miner', { id: id })
                    .then((res) => {
                        if (res.status == 200) {
                            getData()
                            setShowLoader(false)
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            });
                        }
                    })
            }
        });
    }

    const handleEdit = (item) => {
        console.log(item)
        formik.setFieldValue('name', item.name)
        formik.setFieldValue('maxLevel', item.discription)
        formik.setFieldValue('category', item.category)
        formik.setFieldValue('requireLvl', item.condition?.isRequire)
        formik.setFieldValue('requireMiner', item.condition.requiredMiner)
        formik.setFieldValue('requiredMinerLvl', item.condition.requiredLvl)
        handleShow()
        setIsEdit(true)
        setEditId(item._id)
        setEditImg(item.img)
    }

    const getName = (id) => {
        const obj = data.find((obj) => obj.id == id)
        if (id == 'inviteFriend') {
            return "Friend"
        }
        return obj?.name

    }

    const handleLevelEdit = (item) => {
        levelFormik.setFieldValue('number', item.lvlNo)
        levelFormik.setFieldValue('upCost', item.upCost)
        levelFormik.setFieldValue('hourlyIncome', item.hourlyIncome)
        levelFormik.setFieldValue("timeInMinute", item.timeInMinute)
        setIsLevelEdit(true)
        setLevelEditId(item.id)
        console.log(item)
    }

    const handleLevelDelete = (id) => {
        let oldData = currentLevel.filter((obj) => obj.id != id)
        setCurrentLevel(oldData)
    }

    const UpdateLevelToDatabase = () => {
        setShowLoader(true)
        axios.post(process.env.REACT_APP_API_URL + '/miner/update-miner-level', { id: selectedData._id, arr: currentLevel })
            .then((res) => {
                if (res.status == 200) {
                    getData()
                    closeView()
                    setShowLoader(false)
                }
            })
    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-3">
                    <div className="d-flex justify-content-between">
                        <h3 className="text-center">Add Miner</h3>
                        <button className="btn btn-primary" onClick={handleShow}>+ Add</button>
                    </div>

                    <div className="navigation">
                        <Tabs defaultActiveKey="pr&team" id='minerTab' className='mb-3 justify-content-center'>
                            <Tab eventKey="pr&team" title={"PR&Team"}>
                                <div className="row">
                                    {data.map((item) => {
                                        if (Number(item.category) == 0) {
                                            return (
                                                <>
                                                    <div className="col-md-3">
                                                        <div className="mine-card shadow border rounded">
                                                            <div className='mine-img'>
                                                                <img src={item.img} className='img-fluid' width={100} alt="" />
                                                            </div>
                                                            <div>
                                                                <h4>Name : {item.name}</h4>
                                                                <h6>Total Level : {item.level.length}</h6>
                                                                <p>{item.condition.isRequire ? "required level " + item.condition.requiredLvl + " of " + getName(item.condition.requiredMiner) + " to unlock" : "no any Miner Level Needed to unlock"}</p>
                                                            </div>
                                                            <div className="d-flex">
                                                                <button className="btn btn-secondary m-1" onClick={() => handleView(item)}><FaPlus /></button>
                                                                <button className="btn btn-danger m-1" onClick={() => handleDelete(item._id)}><FaTrash /></button>
                                                                <button className="btn btn-primary m-1" onClick={() => handleEdit(item)}><FaPen /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </Tab>
                            <Tab eventKey="markets" title={"Markets"}>
                                <div className="row">
                                    {data.map((item) => {
                                        if (Number(item.category) == 1) {
                                            return (
                                                <>
                                                    <div className="col-md-3">
                                                        <div className="mine-card shadow border rounded">
                                                            <div className='mine-img'>
                                                                <img src={item.img} className='img-fluid' width={100} alt="" />
                                                            </div>
                                                            <div>
                                                                <h4>Name : {item.name}</h4>
                                                                <h6>Total Level : {item.level.length}</h6>
                                                                <p>{item.condition.isRequire ? "required level " + item.condition.requiredLvl + " of " + getName(item.condition.requiredMiner) + " to unlock" : "no any Miner Level Needed to unlock"}</p>
                                                            </div>
                                                            <div className="d-flex">
                                                                <button className="btn btn-secondary m-1" onClick={() => handleView(item)}><FaPlus /></button>
                                                                <button className="btn btn-danger m-1" onClick={() => handleDelete(item._id)}><FaTrash /></button>
                                                                <button className="btn btn-primary m-1" onClick={() => handleEdit(item)}><FaPen /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </Tab>
                            <Tab eventKey="legal" title="Legal">
                                <div className="row">
                                    {data.map((item) => {
                                        if (Number(item.category) == 2) {
                                            return (
                                                <>
                                                    <div className="col-md-3">
                                                        <div className="mine-card shadow border rounded">
                                                            <div className='mine-img'>
                                                                <img src={item.img} className='img-fluid' width={100} alt="" />
                                                            </div>
                                                            <div>
                                                                <h4>Name : {item.name}</h4>
                                                                <h6>Total Level : {item.level.length}</h6>
                                                                <p>{item.condition.isRequire ? "required level " + item.condition.requiredLvl + " of " + getName(item.condition.requiredMiner) + " to unlock" : "no any Miner Level Needed to unlock"}</p>
                                                            </div>
                                                            <div className="d-flex">
                                                                <button className="btn btn-secondary m-1" onClick={() => handleView(item)}><FaPlus /></button>
                                                                <button className="btn btn-danger m-1" onClick={() => handleDelete(item._id)}><FaTrash /></button>
                                                                <button className="btn btn-primary m-1" onClick={() => handleEdit(item)}><FaPen /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </Tab>
                            <Tab eventKey="web3" title="Web3">
                                <div className="row">
                                    {data.map((item) => {
                                        if (Number(item.category) == 3) {
                                            return (
                                                <>
                                                    <div className="col-md-3">
                                                        <div className="mine-card shadow border rounded">
                                                            <div className='mine-img'>
                                                                <img src={item.img} className='img-fluid' width={100} alt="" />
                                                            </div>
                                                            <div>
                                                                <h4>Name : {item.name}</h4>
                                                                <h6>Total Level : {item.level.length}</h6>
                                                                <p>{item.condition.isRequire ? "required level " + item.condition.requiredLvl + " of " + getName(item.condition.requiredMiner) + " to unlock" : "no any Miner Level Needed to unlock"}</p>
                                                            </div>
                                                            <div className="d-flex">
                                                                <button className="btn btn-secondary m-1" onClick={() => handleView(item)}><FaPlus /></button>
                                                                <button className="btn btn-danger m-1" onClick={() => handleDelete(item._id)}><FaTrash /></button>
                                                                <button className="btn btn-primary m-1" onClick={() => handleEdit(item)}><FaPen /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Modal show={view} onHide={closeView} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Miner Details</Modal.Title>
                </Modal.Header>
                <form onSubmit={levelFormik.handleSubmit}>
                    <Modal.Body>
                        <div className="miner-detail">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="number">Level No :</label>
                                    <input
                                        type="text"
                                        name='number'
                                        id='number'
                                        value={levelFormik.values.number}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Level No'
                                    />
                                    {levelFormik.errors.number && levelFormik.touched.number ? (
                                        <div className="text-danger">{levelFormik.errors.number}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="upCost">Upgrade Cost :</label>
                                    <input
                                        type="text"
                                        name='upCost'
                                        id='upCost'
                                        value={levelFormik.values.upCost}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Upgrade cost'
                                    />
                                    {levelFormik.errors.upCost && levelFormik.touched.upCost ? (
                                        <div className="text-danger">{levelFormik.errors.upCost}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="hourlyIncome">Hourly Income :</label>
                                    <input
                                        type="text"
                                        name='hourlyIncome'
                                        id='hourlyIncome'
                                        value={levelFormik.values.hourlyIncome}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Hourly Income'
                                    />
                                    {levelFormik.errors.hourlyIncome && levelFormik.touched.hourlyIncome ? (
                                        <div className="text-danger">{levelFormik.errors.hourlyIncome}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="timeInMinute">Time in Second :</label>
                                    <input
                                        type="number"
                                        name='timeInMinute'
                                        id='timeInMinute'
                                        value={levelFormik.values.timeInMinute}
                                        onChange={levelFormik.handleChange}
                                        className='form-control'
                                        placeholder='Enter Upgrade Time'
                                    />
                                    {levelFormik.errors.timeInMinute && levelFormik.touched.timeInMinute ? (
                                        <div className="text-danger">{levelFormik.errors.timeInMinute}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-12 mb-3 mt-4">
                                    <button className="btn btn-primary w-100" type='submit'>Submit</button>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Table responsive="md">
                                    <thead>
                                        <tr>
                                            <td>Level No</td>
                                            <td>Upgrad Cost</td>
                                            <td>Hourly Income</td>
                                            <td>Time in minute</td>
                                            <td>Edit</td>
                                            <td>Delete</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentLevel.length > 0 && currentLevel.map((item) => (
                                            <tr>
                                                <td>{item.lvlNo}</td>
                                                <td>{item.upCost}</td>
                                                <td>{item.hourlyIncome}</td>
                                                <td>{item.timeInMinute}</td>
                                                <td>
                                                    <button className='btn btn-primary' type='button' onClick={() => handleLevelEdit(item)}>Edit</button>
                                                </td>
                                                <td>
                                                    <button className='btn btn-danger' type='button' onClick={() => handleLevelDelete(item.id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeView}>
                            Close
                        </Button>
                        <Button variant="primary" type='button' onClick={UpdateLevelToDatabase}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Miner</Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="name">Name :</label>
                                <input
                                    type="text"
                                    className={formik.errors.name && formik.touched.name ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Miner Name'
                                    id='name'
                                    name='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.name && formik.touched.name ? (
                                    <span className="text-danger">{formik.errors.name}</span>
                                ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="maxLevel">Description :</label>
                                <input
                                    type="text"
                                    className={formik.errors.maxLevel && formik.touched.maxLevel ? 'form-control border-danger' : "form-control"}
                                    placeholder='Enter Description'
                                    id='maxLevel'
                                    name='maxLevel'
                                    value={formik.values.maxLevel}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.maxLevel && formik.touched.maxLevel ? (
                                    <span className="text-danger">{formik.errors.maxLevel}</span>
                                ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="img">Upload Image</label>
                                <input type="file" className='form-control' id='img' onChange={handleFileChange} />
                                <div className="w-50">
                                    <img src={editImg} className='img-fluid' alt="" />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="category">Category :</label>
                                <select
                                    name='category'
                                    id='category'
                                    className={formik.errors.category && formik.touched.category ? "form-select border-danger" : "form-select"}
                                    value={formik.values.category}
                                    onChange={formik.handleChange}
                                >
                                    <option value="">Select Miner Category</option>
                                    <option value={0}>PR&Team</option>
                                    <option value={1}>Markets</option>
                                    <option value={2}>Legal</option>
                                    <option value={3}>Web3</option>
                                </select>
                                {formik.errors.category && formik.touched.category ? (
                                    <span className="text-danger">{formik.errors.category}</span>
                                ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="requireLvl">Level Required To Unlock : </label>
                                <Form.Check
                                    type='switch'
                                    id='requireLvl'
                                    name="requireLvl"
                                    checked={formik.values.requireLvl}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="requireMiner">Miner Require</label>
                                <select
                                    name="requireMiner"
                                    id="requireMiner"
                                    className='form-select'
                                    value={formik.values.requireMiner}
                                    onChange={formik.handleChange}
                                >
                                    <option value="">Select Miner</option>
                                    <option value="inviteFriend">Invite Friends</option>
                                    {data.map((item) => {
                                        return (
                                            <>
                                                <option value={item.id}>{item.name}</option>
                                            </>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="requireMinerLvl">Required Miner Level</label>
                                <input
                                    type="number"
                                    name='requiredMinerLvl'
                                    id='requiredMinerLvl'
                                    className='form-control'
                                    value={formik.values.requiredMinerLvl}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}
