import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Components/AdminSidebar'
import Loader from '../Components/Loader'
import { isMobile } from 'react-device-detect'
import firebaseApp from '../Firebase/firebase'
import { toast } from 'react-toastify'
import { makeid } from '../Helper/helper'
import moment from 'moment'
import axios from 'axios'
import CryptoJS from 'crypto-js';
import MUIDataTable from 'mui-datatables'


export default function AddDeposit() {
    const [showLoader, setShowLoader] = useState(false)
    const [tonAddress, setTonAddress] = useState('')
    const [date, setDate] = useState('')
    const [investAmount, setInvestAmount] = useState('')
    const [jettonAmount, setJettonAmount] = useState('')

    const [userid, setUserId] = useState('no results')

    const [allActiveUser, setAllActiveUser] = useState([])
    const [allPackages, setAllPackages] = useState([])
    const [allTransactions, setAllTransaction] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        getActiveUser()
    }, [])

    const getPackages = () => {
        axios.get(process.env.REACT_APP_API_URL + '/user/get-package')
            .then((res) => {
                if (res.status == 200) {
                    setAllPackages(res.data)
                    getTransactions()
                }
            })
    }

    const getTransactions = () => {
        axios.get(process.env.REACT_APP_API_URL + '/user/get-all-transaction')
            .then((res) => {
                if (res.status == 200) {
                    setAllTransaction(res.data)
                }
            })
    }

    useEffect(() => {
        let arr = []
        for (let i = 0; i < allActiveUser.length; i++) {
            let completedPackage = 0
            let totalDeposit = 0
            let totalWithdraw = 0

            for (let j = 0; j < allPackages.length; j++) {
                if (allPackages[j].userid == allActiveUser[i].id) {
                    if (allPackages[j].isPackageCompleted == true) {
                        completedPackage++
                    }
                }
            }

            for (let k = 0; k < allTransactions.length; k++) {
                if (allTransactions[k].userid == "cjtMvY6Ayr") {
                    console.log(allTransactions[k])
                }
                if (allTransactions[k].userid == allActiveUser[i].id) {
                    if (allTransactions[k].type == 'deposit') {
                        totalDeposit = totalDeposit + Number(allTransactions[k].amount)
                    } else {
                        totalWithdraw = totalWithdraw + Number(allTransactions[k].amount)
                    }
                }
            }

            let obj = {
                userid: allActiveUser[i].id,
                firstName: allActiveUser[i].telegramId.first_name,
                lastName: allActiveUser[i].telegramId.last_name,
                walletAddress: allActiveUser[i].tonId,
                completedPackage: completedPackage,
                totalDeposit: totalDeposit,
                totalWithdraw: totalWithdraw
            }
            arr.push(obj)
        }
        setData(arr)
    }, [allTransactions])


    // Your 32-byte (256-bit) key
    const SECRET_KEY = "b6d14cb7f4e3d9c6e1c28263f83b1c2e1f4d5c0e8f1a9a0d5e1e5b5d0a5b8b0f";

    // Convert hex key to WordArray format expected by CryptoJS
    const key = CryptoJS.enc.Hex.parse(SECRET_KEY);

    // Encrypt data
    function encryptData(data) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: CryptoJS.enc.Hex.parse('00000000000000000000000000000000') // Use a secure IV in production
        }).toString();
        return ciphertext;
    }

    const findWalletAddress = () => {
        setShowLoader(true)
        axios.post(process.env.REACT_APP_API_URL + '/user/find-wallet', { wallet: tonAddress })
            .then((res) => {
                if (res.status == 200) {
                    setUserId(res.data.id)
                    setShowLoader(false)
                    if (res.data.length <= 0) {
                        setUserId("not found")
                    }
                }
            })
    }

    const AddDeposit = () => {
        setShowLoader(true)

        const transaction = {
            id: makeid(10),
            date: date,
            walletaddress: '',
            amount: Number(investAmount),
            jettomAmount: Number(jettonAmount),
            type: 'deposit',
            userid: userid
        }

        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-transaction', { data: encryptData(transaction) })
            .then((res) => {
                if (res.status == 200) {
                    AddCompletePackage()
                }
            })
    }

    const AddCompletePackage = () => {
        const packages = {
            id: makeid(10),
            date: date,
            investAmount: Number(investAmount),
            isPackageCompleted: false,
            totalGet: 0,
            totalReward: Number(investAmount) * 3,
            userid: userid,
            Earning: []
        }

        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-package', { data: encryptData(packages) })
            .then((res) => {
                if (res.status == 200) {
                    PlusDepositToUser()
                }
            })
    }

    const PlusDepositToUser = () => {
        let obj = {
            id: userid,
            amount: investAmount
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-user-deposit', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                    setInvestAmount('')
                    setDate('')
                    setJettonAmount('')
                    setUserId('no results')
                    setTonAddress('')
                    toast.success('Amount Added Successfully', {
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            marginTop: "10px"
                        },
                    })
                }
            })
    }

    const handleDateChange = (e) => {
        let date = e.target.value
        let timestamp = new Date(date).getTime()
        setDate(timestamp)
    }

    const getActiveUser = () => {
        axios.get(process.env.REACT_APP_API_URL + '/user/get-active-user')
            .then((res) => {
                if (res.status == 200) {
                    setAllActiveUser(res.data)
                    getPackages()

                }
            })
    }

    const col = [
        {
            name: "userid",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "walletAddress",
            label: "Wallet Address",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "completedPackage",
            label: "Completed Package",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalDeposit",
            label: "Total Deposit",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalWithdraw",
            label: "Total Withdraw",
            options: {
                filter: false,
                sort: false,
            }
        },
    ]

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage: 100,
        responsive: "standard",
        pagination: false
    };

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-3">
                    <h3>Find user id from TON Address</h3>
                    <div className="row">
                        <div className="col-md-4">
                            <input type="text" value={tonAddress} placeholder='Enter Wallet Address' onChange={(e) => setTonAddress(e.target.value)} className="form-control" />
                        </div>
                        <div className="col-md-4">
                            <button className="btn-primary btn w-100" onClick={findWalletAddress}>SUBMIT</button>
                        </div>
                        <div className="col-md-4">
                            <p>{userid}</p>
                        </div>
                    </div>
                </div>
                <div className="container mt-3">
                    <h3>Add Deposit</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="date">Date : </label>
                            <input type="datetime-local" value={moment(date).format('YYYY-MM-DDTHH:mm')} onChange={handleDateChange} className='form-control' />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="investAmount">Invest Amount : </label>
                            <input type="text" value={investAmount} onChange={(e) => setInvestAmount(e.target.value)} className='form-control' />
                        </div>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="jetton">Jetton Amount : </label>
                            <input type="text" value={jettonAmount} onChange={(e) => setJettonAmount(e.target.value)} className='form-control' />
                        </div>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="userid">UserId : </label>
                            <input type="text" value={userid} onChange={(e) => setUserId(e.target.value)} className='form-control' />
                        </div>
                    </div>
                    <button className="btn btn-primary w-100 mt-3" onClick={AddDeposit}>Add Deposit</button>
                </div>
                <div className="container">
                    <MUIDataTable
                        title={"Deposit"}
                        data={data}
                        columns={col}
                        options={options}
                    />
                </div>
            </div>
        </>
    )
}
