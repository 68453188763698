import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Components/AdminSidebar'
import { isMobile } from 'react-device-detect'
import Loader from '../Components/Loader'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import firebaseApp from '../Firebase/firebase'
import axios from 'axios'

export default function AdminTransaction() {
    const [showLoader, setShowLoader] = useState(false)
    const [walletBalance, setWalletBalance] = useState(0)
    const [Deposit, setDeposit] = useState([])
    const [Withdraw, setWithdraw] = useState([])

    useEffect(() => {
        getWalletBalance()
        getTransaction()
    }, [])

    const getTransaction = () => {
        let dip = []
        let wit = []
        axios.get(process.env.REACT_APP_API_URL + '/package/get-today-transaction')
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                    res.data.forEach((doc) => {
                        if (doc.type == 'deposit') {
                            dip.push(doc)
                        }
                        if (doc.type == 'withdraw') {
                            wit.push(doc)
                        }
                        setDeposit(dip)
                        setWithdraw(wit)

                    });
                }
            })
    }

    const getWalletBalance = async () => {
        const response = await fetch(`https://toncenter.com/api/v3/walletInformation?address=UQDEj-5PAlOXduD4u_ZkUTipDb7z4NyxVb4ymwUPnPRKQVKC&use_v2=true`);
        const data = await response.json();
        console.log(data);
        if (data.balance) {
            let jetton = Number(data.balance) / (10 ** 9)
            setWalletBalance(jetton)
        }
    }
    const col = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{moment(value).format('DD-MM-YYYY HH:mm')}</p>
                    )
                }
            }
        },
        {
            name: "amount",
            label: "USDT",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
        {
            name: "jettomAmount",
            label: "TON",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
    ]

    const WitCol = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{moment(value).format('DD-MM-YYYY HH:mm')}</p>
                    )
                }
            }
        },
        {
            name: "amount",
            label: "USDT",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
        {
            name: "jettonAmount",
            label: "TON",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
    ]



    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage: 100,
        responsive: "standard",
        pagination: false
    };


    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-3">
                    <h3>Balance : {walletBalance} TON</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <MUIDataTable
                                title={"Deposit"}
                                data={Deposit}
                                columns={col}
                                options={options}
                            />
                        </div>
                        <div className="col-md-6">
                            <MUIDataTable
                                title={"Withdraw"}
                                data={Withdraw}
                                columns={WitCol}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
