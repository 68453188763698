import React, { useEffect, useState } from 'react'
import Loader from '../Components/Loader'
import { isMobile } from 'react-device-detect'
import AdminSidebar from '../Components/AdminSidebar'
import firebaseApp from '../Firebase/firebase'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import axios from 'axios'

export default function History() {
    const [showLoader, setShowLoader] = useState(true)
    const [Deposit, setDeposit] = useState([])
    const [Withdraw, setWithdraw] = useState([])
    const { id } = useParams()
    useEffect(() => {
        getTransaction()
    }, [])

    const getTransaction = () => {
        let dip = []
        let wit = []
        axios.post(process.env.REACT_APP_API_URL + '/package/get-all-transaction', { id: id })
            .then((res) => {
                if (res.status == 200) {
                    res.data.forEach((obj) => {
                        setShowLoader(false)
                        if (obj.type == 'deposit') {
                            dip.push(obj)
                        }
                        if (obj.type == 'withdraw') {
                            wit.push(obj)
                        }
                        setDeposit(dip)
                        setWithdraw(wit)
                    })
                }
            })
    }

    const col = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{moment(value).format('DD-MM-YYYY')}</p>
                    )
                }
            }
        },
        {
            name: "amount",
            label: "USDT",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
        {
            name: "jettomAmount",
            label: "TON",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
    ]

    const WitCol = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{moment(value).format('DD-MM-YYYY')}</p>
                    )
                }
            }
        },
        {
            name: "amount",
            label: "USDT",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
        {
            name: "jettonAmount",
            label: "TON",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{Number(value).toFixed(2)}</p>
                    )
                }
            }
        },
    ]



    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage: 100,
        pagination: false
    };


    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div className={isMobile ? "MobileScreen" : "BigScreen"} id="page-container">
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <MUIDataTable
                                title={"Deposit"}
                                data={Deposit}
                                columns={col}
                                options={options}
                            />
                        </div>
                        <div className="col-md-6">
                            <MUIDataTable
                                title={"Withdraw"}
                                data={Withdraw}
                                columns={WitCol}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
